<template>
  <v-row>
    <v-col cols="12" sm="4" class="mt-5">
        <v-card 
          :loading="accountLoading"
          :disabled="accountLoading" 
          class="account-card pa-3"
        >
          <div class="account-line">
              <v-avatar
                  rounded
                  size="120"
              >
                  <v-img v-show="!accountLoading" :src="$store.state.account.account.avatar||defaultAvatar"></v-img>
              </v-avatar>
          </div>
          <div class="name title text-center mb-4">
              {{ $store.state.account.account.name }}
          </div>
          <div class="account-details">
              <div class="name title">{{ $t('Details') }}</div>
              <hr/>
              <div class="details-info">
                  <p class="details-line mb-2"><span class="line">ID:</span><span>{{ accountDataLocale.id }}</span></p>
                  <p class="details-line mb-2"><span class="line">{{ $t('Email') }}:</span><span>{{ $store.state.account.account.email }}</span></p>
                  <p class="details-line mb-2"><span class="line">{{ $t('Date of registration') }}:</span><span>{{ accountDataLocale.created_at }}</span></p>
              </div>
          </div>
          <div class="account-edit">
              <v-row justify="center">
                  <v-dialog
                      v-model="editUserDialog"
                      persistent
                      max-width="600px"
                  >
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      >
                          {{ $t("Edit") }}
                      </v-btn>
                  </template>
                  <v-card>
                      <v-card-title>
                          <span class="text-h5">{{ $t('Edit account') }}</span>
                      </v-card-title>
                      <v-card-text>
                          <v-container>
                            <v-form ref="form" class="multi-col-validation mt-6">
                              <v-row>
                                  <v-col cols="12" class="d-flex">                          
                                      <v-avatar
                                          rounded
                                          size="120"
                                          class="me-6"
                                      >
                                          <v-img v-show="!accountLoading" :src="accountDataLocale.avatar||defaultAvatar"></v-img>
                                      </v-avatar>
                                  <!-- upload photo -->
                                  <div>
                                      <v-btn
                                          color="primary"
                                          class="me-3 mt-5"
                                          @click="$refs.refInputEl.click()"
                                      >
                                      <v-icon class="d-sm-none">
                                          {{ icons.mdiCloudUploadOutline }}
                                      </v-icon>
                                      <span class="d-none d-sm-block">{{ $t('Upload new photo') }}</span>
                                      </v-btn>

                                      <input
                                          ref="refInputEl"
                                          type="file"
                                          v-on:change="onFileChange"
                                          accept=".jpeg,.png,.jpg,GIF"
                                          :hidden="true"
                                      />
                                      <p class="text-sm mt-5">
                                          {{ $t('Allowed JPG, GIF or PNG. Max size of 800K') }}
                                      </p>
                                  </div>
                                  </v-col>
                                  <v-col cols="12">
                                      <v-text-field
                                          v-model="accountDataLocale.name"
                                          :label="$t('Full name')"
                                          dense
                                          outlined
                                          required
                                          :rules="[validators.required,validators.lengthValidator(accountDataLocale.name,4),validators.urlBlockValidator]"
                                      ></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                      <v-text-field
                                          v-model="accountDataLocale.email"
                                          :label="$t('Email')"
                                          dense
                                          outlined
                                          required
                                          :error-messages="errors.email"
                                          :rules="[validators.required,validators.emailValidator]"
                                      ></v-text-field>
                                  </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="blue darken-1"
                              text
                              @click="editUserDialog = false"
                          >
                              {{ $t('Close') }}
                          </v-btn>
                          <v-btn
                              color="blue darken-1"
                              text
                              @click="updateUser()"
                          >
                              {{ $t('Save') }}
                          </v-btn>
                      </v-card-actions>
                  </v-card>
                  </v-dialog>
              </v-row>
          </div>
      </v-card>
    </v-col>
    <v-col cols="12" sm="8" class="mt-5">
      <v-card flat class="pa-3 account-upgrade" :loading="accountLoading" :disabled="accountLoading">
        <v-card-title>{{ $t('Upgrade account to Vendor') }}</v-card-title>
        <v-card-text v-if="!accountDataLocale.seller_requests || !accountDataLocale.seller_requests.length">
          {{ $t('Do you want to become a Vendor and sell us your Online Arbitrage Deals? Send a request to upgrade your account to a Vendor.') }}
        </v-card-text>
        <v-card-text v-else>
          <v-timeline
              align-top
              dense
          >
            <v-timeline-item
                v-for="note in accountDataLocale.seller_requests"
                :key="note.id"
                :color="SRStatusColor[note.status]"
                small
            >
              <div>
                <div class="font-weight-normal">
                  <strong> {{ $t('Status') }}: <span :class="SRStatusColor[note.status]+'--text'">{{ SRStatus[note.status] }}</span></strong>
                </div>
                <div>{{ note.date }}</div>
                <div>{{ $t(sellerStatusText(note.status, note.note)) }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-btn color="success"
          v-if="accountDataLocale.is_seller"
          @click="openMarketplaceLink"
          :loading="openMarketLoading"
          class="ml-5"
        >
          {{ $t('go to the store') }}
        </v-btn>
        <v-dialog
            v-model="updateToSellerDialog"
            v-else
            persistent
            max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"
              v-bind="attrs"
              v-on="on"
              :disabled="accountDataLocale.active_seller_request"
              :class="{'bias-button': accountDataLocale.seller_requests }"
            >
              {{ $t('Sell OA Deals') }}
            </v-btn>
          </template>
          <v-card>
            <v-form ref="form" :v-model="valid">
              <v-card-title>
                <span class="text-h5">{{ $t('Sell OA Deals') }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span>
                        {{ $t('We carefully select sellers who can sell on the platform. Please describe your work experience and let us know how many online arbitrage deals you plan to sell. You will be notified by email when we approve your application.') }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                          outlined
                          name="input"
                          :rules="noteRules"
                          v-model="updateNote"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
                <v-checkbox
                  class="mt-0"
                  v-model="sellerAgreement"
                  :rules="sellerAgreementRules"
                  :true-value="1"
                  :false-value="0"
                >
                  <template #label>
                    <div class="d-flex align-center flex-wrap">
                      <span class="me-2">I accept the</span><a @click.stop="" target="_blank" href="https://www.sellerassistant.deals/supplier-agreement">seller agreement</a>
                    </div>
                  </template>
                </v-checkbox>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="updateToSellerDialog = false"
                >
                  {{ $t('Close') }}
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="updateAccount()"
                    :class="{'bias-button': accountDataLocale.seller_requests }"
                >
                  {{ $t('Sell OA Deals') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mdiAlertOutline, mdiCloudUploadOutline, mdiPlus} from '@mdi/js'
import {computed, getCurrentInstance, ref, watch} from '@vue/composition-api'
import store from "@/store";
import {emailValidator, lengthValidator, required, urlBlockValidator} from "@core/utils/validation";
import axios from "@axios";
import {useRouter} from "@core/utils";

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const form = ref(null);
    const editUserDialog = ref()
    const sellerAgreement = ref(0)
    const sellerAgreementRules = [v => !!v || 'Seller agreement required']
    const updateToSellerDialog = ref(false)
    const updateNote = ref('')
    const noteRules = [v => !!v || 'Update info required', v => v.length > 20 || 'Minimal length of characters is 20']
    const valid = ref(false)
    const openMarketLoading = ref(false)
    const openMarketplaceLink = () => {
      openMarketLoading.value = true
          axios.get('/auth/me').then(response => {
            const user = response.data.data
            const { ability: userAbility } = user
            vm.$ability.update(userAbility)
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            router.push({ name: 'user-market'})
          })
    }

    const updateAccount = () => {
      if (!form.value.validate()) {
        return
      }
      axios.post("seller-request", {note: updateNote.value}).then(res => {
        updateToSellerDialog.value = false
        sellerAgreement.value = 0
        store.dispatch('account/getAccount')
        vm.$notify({
          group: 'deals',
          title: vm.$t("Seller Request"),
          text: vm.$t("seller request sent successfully"),
          type: "success"
        })
      }).catch(err => {
        store.dispatch('account/getAccount')
      })
    }

    const onFileChange = (e) => {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      getBase64(files[0])
    }
    const getBase64 = (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        updateImg(reader.result)
      };
      reader.onerror = function (error) {
        // console.log('Error: ', error);
      };
    }
    const updateImg = (base64) => {
      accountDataLocale.value = {...Object.assign(accountDataLocale.value, {avatar: base64})}
    }
    const accountDataLocale = ref({})
    const errors = ref({})
    const accountLoading = computed(() => {
      accountDataLocale.value = Object.assign({}, store.state.account.account, accountDataLocale.value)
      return store.state.account.loading
    })

    watch(() => editUserDialog.value, (Open, Close) => {  
      if(Open) {
          accountDataLocale.value = Object.assign({}, store.state.account.account, accountDataLocale.value)
      }
      if(Close) {
          accountDataLocale.value = {}
      }
    })

    const updateUser = () => {
      if (!form.value.validate()) {
        return
      }
      errors.value = {}
      axios.post('auth/user-update', Object.assign(accountDataLocale.value, {_method: 'put'}))
          .then(res => {
            store.dispatch('account/getAccount')
            editUserDialog.value = false
            vm.$notify({
              group: 'deals',
              title: vm.$t("Profile Update"),
              text: vm.$t("Profile updated successfully"),
              type: "success"
            })
          })
          .catch(err => {
            if (err.response.data.errors) {
              errors.value = err.response.data.errors
              setTimeout(() => errors.value = {}, 5000)
            }
          })
    }
    const resetForm = () => {
      accountDataLocale.value = {...store.state.account.account}
    }

    const sellerStatusText = (status, note) => {
      switch (status) {
        case 0:
          return 'The application will be considered within 7 days.'
        case 2:
          return 'Go to the store to set up your account and start your first sale.'
        case 1:
          return note
      }
    }

    return {
      sellerAgreement,
      sellerAgreementRules,
      editUserDialog,
      onFileChange,
      form,
      accountDataLocale,
      accountLoading,
      errors,
      updateToSellerDialog,
      valid,
      noteRules,
      updateNote,
      openMarketLoading,
      sellerStatusText,
      updateAccount,
      resetForm,
      updateUser,
      openMarketplaceLink,
      defaultAvatar: require('@/assets/images/avatars/1.png'),
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiPlus
      },
      SRStatusColor: [
        'warning',
        'error',
        'success',
      ],
      SRStatus: [
          'Application under consideration',
          'Application rejected',
          'Application approved'
      ],
      validators: {
        required,
        emailValidator,
        lengthValidator,
        urlBlockValidator
      },
    }
  },
}
</script>

