<template>
  <v-card flat class="mt-5 d-flex" :loading="productLoading" :disabled="productLoading">
    <v-form ref="form" style="width: 600px">
      <div class="px-3">
        <v-card-text class="pt-5">
          <div class="d-flex">
            <v-row>
              <v-col>
                <!-- current password -->
                <v-text-field
                    v-model="currentPassword"
                    :type="isCurrentPasswordVisible ? 'text' : 'password'"
                    :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    label="Current Password"
                    outlined
                    dense
                    :error-messages="error.old_password"
                    :rules="[validators.required]"
                    @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                ></v-text-field>

                <!-- new password -->
                <v-text-field
                    v-model="newPassword"
                    :type="isNewPasswordVisible ? 'text' : 'password'"
                    :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    label="New Password"
                    outlined
                    dense
                    :error-messages="error.password"
                    :rules="[validators.required, validators.passwordValidator]"
                    @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                ></v-text-field>

                <!-- confirm password -->
                <v-text-field
                    v-model="cPassword"
                    :type="isCPasswordVisible ? 'text' : 'password'"
                    :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    label="Confirm New Password"
                    outlined
                    dense
                    class="mt-3"
                    :error-messages="error.password_confirmation"
                    :rules="[validators.required, validators.confirmedValidator(cPassword, newPassword)]"
                    @click:append="isCPasswordVisible = !isCPasswordVisible"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>

      <div class="px-3">
        <v-card-text>
          <v-btn
              color="primary"
              class="me-3 mt-3"
              @click.prevent="changePassword"
          >
            {{ $t('Update Password') }}
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
     <v-img
          contain
          max-width="200"
          style="position: relative; margin-left: 10%"
          src="@/assets/images/3d-characters/pose-m-1.png"
          class="security-character mt-6"
      ></v-img>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline} from '@mdi/js'
import {computed, getCurrentInstance, ref} from '@vue/composition-api'
import {confirmedValidator, passwordValidator, required} from "@core/utils/validation";
import store from "@/store";
import axios from "@axios";

export default {
  setup() {
    const form = ref(null)
    const error = ref({})
    const vm = getCurrentInstance().proxy
    const productLoading = computed(() => store.state.account.loading)
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')

    const changePassword = () => {
      if (!form.value.validate()) return
      error.value = {}
      axios.post('auth/password-update', {
        old_password: currentPassword.value,
        password: newPassword.value,
        password_confirmation: cPassword.value,
        _method: 'put'
      }).then(res => {
        vm.$notify({
          group: 'deals',
          title: vm.$t("Password Update"),
          text: vm.$t("Password changed successfully"),
          type: "success"
        })
      }).catch(err => {
        if (err.response?.data?.errors) {
          error.value = err.response.data.errors
          setTimeout(() => error.value = {}, 5000)
        } else {
          vm.$notify({
            group: 'deals',
            title: vm.$t("Password Update"),
            text: err.response.data.message,
            type: "error"
          })
        }
      })
    }
    return {
      error,
      form,
      productLoading,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      changePassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        passwordValidator,
        confirmedValidator,
        required
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}

@media screen and (max-width: 600px) and (min-width: 0px) {
  .security-character {
    display: none;
  }
}
</style>
