<template>
  <div>
    <account-role-title role="Buyer" />
    <!-- <breadcrumbs /> -->
    <span class="title d-block mb-2 ml-1">{{ $t("Account") }}</span>
    <!-- tabs -->
    <v-tabs
        v-model="tab"
        show-arrows
    >
      <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="$router.push({name:'user-account', params:{tab:tab.route}, query:{t:new Date().getTime()}})"
      >
        <v-icon
            size="20"
            class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <!-- tab component -->
        <component :is="tab.component" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiPlusBox,
  mdiBellOutline, mdiCreditCard,
} from '@mdi/js'
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import AccountSettingsAccount from './AccountSettingsAccount'
import AccountSettingsSecurity from './AccountSettingsSecurity'
import AmzIntegration from './AmzIntegration'
import AccountBalance from "./AccountBalance"
import store from "@/store"
import { useRouter } from '@core/utils'

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountBalance
  },
  setup() {
    const tabs = [
      { title: 'Profile', icon: mdiAccountOutline, route: 'profile', component: AccountSettingsAccount },
      { title: 'Amazon Account', icon: mdiPlusBox, route: 'amazon-account', component: AmzIntegration },
      { title: 'Billing', icon: mdiLockOpenOutline, route: 'billing', component: AccountBalance },
      { title: 'Security', icon: mdiCreditCard, route: 'security', component: AccountSettingsSecurity },
    ]
    const { route } = useRouter()
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
    // tabs
    onBeforeMount(() => {
      store.dispatch('account/getAccount')
    })

    watch(
      () => route.value.params.tab, (newTab) => {
        tab.value = tabs.findIndex(tab => tab.route === newTab)
      }
    )

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiCreditCard
      },
    }
  },
}
</script>
<style scoped>
.v-item-group{
  background-color: transparent !important;
}
</style>
