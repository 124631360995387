<template>
  <v-card flat class="pa-3 mt-6" :loading="loading" :disabled="loading">
    <v-card-title>
      <span>{{ $t("Amazon Seller Accounts") }}</span>
    </v-card-title>
    <v-card-subtitle class="mb-4">
      <span>{{ $t("Select the region and marketplace you want to add.") }}</span>
    </v-card-subtitle>
      <v-row justify="center">
        <v-simple-table class="mb-4" style="width: 100%">
          <template v-slot:default>
            <thead v-if="amzCredentials.length > 0">
              <tr>
                <th class="text-left">{{ $t("Region") }}</th>
                <th class="text-left">{{ $t("Marketplace") }}</th>
                <th class="text-left">{{ $t("Status") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="amzCredentials.length > 0" v-for="amzCredential in amzCredentials" :key="amzCredential.id">
                <td>{{ $t(supportedRegions[amzCredential.marketplace_region].title) }}</td>
                <td>{{ marketplaces[amzCredential.marketplace_id].domain }}</td>
                <td>
                  <span v-if="amzCredential.status == 1" class="text-success">{{ $t('Connected') }}</span>
                  <span 
                    v-if="amzCredential.status != 1"
                    class="text-danger"
                    :title="$t('Reconnect')"
                  >
                    {{ $t('Disconnected') }} &nbsp;
                    <v-btn
                      icon
                      @click="connectMarketplace(amzCredential.marketplace_id)"
                      class="text-danger"
                    >
                      <v-icon color="error"> {{ icons.mdiRefresh }} </v-icon>
                    </v-btn>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-row>
    <div>
      <v-row justify="center">
        <v-expansion-panels v-model="panel" accordion class="deals-amazon-accordion">
          <v-expansion-panel
            v-for="(regionData, codeRegion) in supportedRegions"
            :key="codeRegion"
            @change="toogleAccordion(codeRegion)"
          >
            <v-expansion-panel-header
              style="flex-direction: row-reverse"
              :expand-icon="selectedPanel === supportedRegions[codeRegion].id && showPanel ? icons.mdiMinusCircle : icons.mdiPlusCircle"
              disable-icon-rotate
            >
              <span class="ml-6">{{ regionData.title }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-radio-group class="mt-2" v-model="selectedMarketplace">
                  <div v-for="(value, name) in marketplaceByRegion(codeRegion)" class="d-flex align-items-center my-2 ml-2" :key="name">
                    <v-radio :value="value.id"></v-radio>
                    <span 
                      class="pointer" 
                      @click="selectedMarketplace = value.id" 
                      v-model="region"
                    >
                      {{ value.domain }} 
                    </span>
                  </div>
              </v-radio-group>
              <div class="row" style="margin:0">
                <div class="col-auto">
                  <v-btn
                    color="primary"
                    :disabled="!selectedMarketplace"
                    @click="connectMarketplace(selectedMarketplace)"
                  >
                    {{ $t('Connect Amazon Account') }}
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import {onBeforeMount, ref, watch} from "@vue/composition-api";
import axios from "@axios";
import {mdiChatQuestion, mdiMinusCircle, mdiPlusCircle, mdiRefresh} from "@mdi/js";
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: "AmzIntegration",
  setup() {
    const vm = getCurrentInstance().proxy
    const loading = ref(true)
    const panel = ref(0)
    const selectedPanel = ref(0)
    const showPanel = ref(true)
    const region = ref(false)
    const selectedMarketplace = ref('ATVPDKIKX0DER')
    const marketplaces = ref([])
    const amzCredentials = ref([])
    const supportedRegions = ref({
      na: {id: 0, status: 'disconnected', title: 'North America'},
      eu: {id: 1, status: 'disconnected', title: 'Europe'},
      fe: {id: 2, status: 'disconnected', title: 'Far East'},
    })
    const connectingRegion = ref('')

    const toogleAccordion = (region) => {
      selectedPanel.value = supportedRegions.value[region].id
      showPanel.value = !showPanel.value
    }

    const marketplaceByRegion = (region) => {
      return Object.keys(marketplaces.value)
          .filter((k) => marketplaces.value[k].region === region)
          .reduce((obj, key) => {
            obj[key] = marketplaces.value[key];
            return obj;
          }, {});
    }

    const connectMarketplace = ($marketplaceId) => {
      axios.get(`/login-with-amazon?marketplace_id=${$marketplaceId}`).then(res =>
          window.location.href = res.data
      )
    }
    const getSupportedMarketplaces = () => {
      axios.get("/amzmarketplaces/supported").then((response) => {
        marketplaces.value = response.data;
      });
    }
    onBeforeMount(async () => {
      getSupportedMarketplaces()
      await update()
      if(!amzCredentials.value[0]){
        connectingRegion.value = 'na'
      }
      if(vm.$route.query.error){
        vm.$notify({
          group: 'deals',
          title: 'Amazon Account Connection Failed',
          type: 'error',
          text: vm.$t(vm.$route.query.error),
          max: 5
        })
      } else if(vm.$route.query.success) {
        vm.$notify({
          group: 'deals',
          title: 'Amazon Account Connection',
          type: 'success',
          text: vm.$t(vm.$route.query.success),
          max: 5
        })
      }
    })
    
    const update = async () => {
      await axios.get("/amzcredentials").then((response) => {
        amzCredentials.value = response.data.creds;
        amzCredentials.value.map((element) => {
          supportedRegions.value[element.marketplace_region].status = 'connected';
        });
        loading.value = false
      });
    }

    const connectMore = (val) => {
      connectingRegion.value = val;
    }

    return {
      showPanel,
      toogleAccordion,
      panel,
      selectedPanel,
      region,
      selectedMarketplace,
      amzCredentials,
      supportedRegions,
      connectingRegion,
      marketplaces,
      loading,
      marketplaceByRegion,
      getSupportedMarketplaces,
      update,
      connectMore,
      connectMarketplace,
      icons: {
        mdiChatQuestion,
        mdiPlusCircle,
        mdiMinusCircle,
        mdiRefresh
      }
    }
  }
}
</script>
<style scoped>
  .block-enter {
    opacity: 0;
  }
  .block-enter-active {
    animation : slide-in 1s ease-out forwards;
    transition : opacity 1s;
  }
  .block-leave {
    opacity: 1;
  }
  .block-leave-active {
    animation : slide-out 1s ease-out forwards;
    transition : opacity 1s;
    opacity: 0;
  }
</style>
